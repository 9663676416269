import { Injectable, Injector } from "@angular/core";
import { RtcRooms } from "../../../model/Rtc/Enums/RtcRooms";
import { IForceTesterPayload } from "../../../model/Rtc/MessagePayloads/ForceTesterPayload";
import { ISpringTesterPayload } from "../../../model/Rtc/MessagePayloads/SpringTesterPayload";
import { IRunChartPayload } from "../../../model/Rtc/MessagePayloads/RunChartPayload";

import { RtcRoomServiceFactory } from "@mt-ng2/rtc-module";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class RtcRoomsResolverService {
    private _roomResolver: RtcRoomServiceFactory;
    constructor(injector: Injector) {
        this._roomResolver = injector.get(RtcRoomServiceFactory);
    }

    getForceTesterRoom(): Observable<IForceTesterPayload> {
        return this._roomResolver.getRoom<IForceTesterPayload>(RtcRooms.ForceTester).listen();
    }

    getSpringTesterRoom(): Observable<ISpringTesterPayload> {
        return this._roomResolver.getRoom<ISpringTesterPayload>(RtcRooms.SpringTester).listen();
    }

    getRunChartRoom(): Observable<IRunChartPayload> {
        return this._roomResolver.getRoom<IRunChartPayload>(String(RtcRooms.RunChart)).listen();
    }
}
