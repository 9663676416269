<div class="text-right" *ngIf="canGenerateRandomTests">
    <br>
    <button class="btnGenerateRandomFile btn" (click)="generateRandomTestFile()">Generate Random Test</button>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col col-md-4">
            <div class="row">
                <div class="col col-md-10">
                    <h4>Current Equation</h4>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-10 disabled-looking-box">
                    HEIGHT@LOAD <span class="no-wrap">+.020</span> <span class="no-wrap">- X92</span> <span class="no-wrap">- SPRING</span> = <span class="no-wrap">-ADJUSTMENT</span>
                </div>
            </div><br>
            <div class="row">
                <div class="col col-md-10">
                    <h4>Current Constants</h4>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-5">
                    <input type="text" value=".020" disabled="disabled" class="form-control text-center">
                </div>
                <div class="col col-md-5">
                    <input type="number" [value]="testerPayload.clutchPlateGroveDistance.toFixed(decimalPlaces)" title="" disabled="disabled" class="form-control text-center">
                </div>
            </div><br>
            <div class="row">
                <div class="col col-md-5">
                    <input type="text" value="X92" disabled="disabled" class="form-control text-center">
                </div>
                <div class="col col-md-5">
                    <input type="number" [value]="testerPayload.theoreticalPresetHeight.toFixed(decimalPlaces)" title="" disabled="disabled" class="form-control text-center">
                </div>
            </div><br>
            <div class="row">
                <div class="col col-md-5">
                    <input type="text" value="Spring" disabled="disabled" class="form-control text-center">
                </div>
                <div class="col col-md-5">
                    <input type="number" [value]="testerPayload.currentSpringThickness.toFixed(decimalPlaces)" title="" disabled="disabled" class="form-control text-center">
                </div>
            </div><br>
            <div class="row">
                <div class="col col-md-10">
                    <h4>Adjustment Limits</h4>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-5">
                    <input type="number" [value]="testerPayload.comparisonThresholdMin.toFixed(decimalPlaces)" title="" disabled="disabled" class="form-control text-center">
                </div>
                <div class="col col-md-5">
                    <input type="number" [value]="testerPayload.comparisonThresholdMax.toFixed(decimalPlaces)" title="" disabled="disabled" class="form-control text-center">
                </div>
            </div><br>
            <div class="row">
                <div class="col col-md-10">
                    <h4>Spring Tester Output (Height @ Load)</h4>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-5">
                    <input type="text" [value]="heightAt" disabled="disabled" class="form-control text-center">
                </div>
                <div class="col col-md-5">
                    <input type="number" [value]="testerPayload.heightLoad.toFixed(decimalPlaces)" title="" disabled="disabled" class="form-control text-center">
                </div>
            </div><br>
            <div class="row">
                <div class="col col-md-10">
                    <h4 class="ajv-header">Adjustment Value</h4>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-10">
                    <input
                        type="text"
                        [value]="adjustedValueString"
                        title=""
                        disabled="disabled"
                        [ngClass]="'form-control text-center' + adjustmentResultClass + ' ajv-field'">
                </div>
            </div>
        </div>
        <div class="col col-md-8">
            <div class="row">
                <div class="col col-md-4">
                    <h4 class="rn-tid-header">Run Number</h4>
                </div>
                <div class="col col-md-4" *ngIf="canGenerateRandomTests; else operatorView">
                    <h4 class="rn-tid-header">Test ID</h4>
                </div>
                <ng-template #operatorView>
                    <div class="col col-md-4">
                        <h4 class="rn-tid-header">Test Force</h4>
                    </div>
                </ng-template>
            </div>
            <div class="row">
                <div class="col col-md-4">
                    <input type="number" [value]="testerPayload.runNo" disabled="disabled" class="form-control text-center rn-tid-field">
                </div>
                <div class="col col-md-4" *ngIf="canGenerateRandomTests">
                    <input type="number" class="form-control rn-tid-field" [(ngModel)]="newTestId" [min]="minTestId" [max]="maxTestId" [title]="testIdMessage">
                </div>
                <div class="col col-md-4" *ngIf="canGenerateRandomTests">
                    <button class="btn btn-primary rn-tid-field rn-tid-button" (click)="addTestId()">Add Test ID</button>
                </div>
                <div class="col col-md-4" *ngIf="!canGenerateRandomTests">
                    <input type="number" [value]="newTestId" disabled="disabled" class="form-control text-center rn-tid-field">
                </div>
            </div><br><br><br>
            <div class="row">
                <div class="col col-md-12">
                    <div [ngClass]="'result-display text-center' + resultClass">
                        <h1>{{resultText}}</h1>
                    </div>
                </div>
            </div><br>
        </div>
    </div>
</div>

