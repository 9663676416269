import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'debounced-type-ahead',
    templateUrl: './debounced-type-ahead.component.html',
    styleUrls: ['./debounced-type-ahead.component.scss']
})

export class DebouncedTypeAheadComponent implements OnInit {
    @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
    @Input() search: (query: string) => Observable<any>;
    @Input() filter?: (any) => any[];
    @Input() debounceTime?: number = 1000;
    @Input() selectAllItem?: any;
    @Input() placeholder?: string;
    @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output() clearSelected: EventEmitter<any> = new EventEmitter<any>();

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event): void {
        if (this.inputElement.nativeElement.contains(event.target)) {
            return;
        }
        this.isDropdownVisible = false;
    }

    results: any[] = [];
    isDropdownVisible = false;
    selectedValue = "";

    ngOnInit(): void {
        this.addSelectAllItem();
        this.setupDebounce();
    }

    addSelectAllItem() {
        if (this.selectAllItem)
            this.results.unshift(this.selectAllItem);
    }

    setupDebounce() {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        fromEvent(this.inputElement.nativeElement, 'input').pipe(
            map((event: any) => event.target.value),
            debounceTime(this.debounceTime),
            distinctUntilChanged(),
            switchMap((query: string) => this.search(query))
        ).subscribe(
            data => {
                this.results = this.filter ? this.filter(data) : data;
                this.addSelectAllItem();
                this.isDropdownVisible = this.results.length > 0;
            },
            error => console.error('Error: ', error)
        );
    }

    onSelect(result: any) {
        this.itemSelected.emit(result);
        this.selectedValue = result.name;
        this.isDropdownVisible = false;
    }

    backspaceCheck($event: KeyboardEvent) {
        if ($event.key === 'Backspace' && this.selectedValue !== '' && this.selectedValue.length > 0) {
            this.clear();
            this.selectedValue = "";
        }
    }

    clear(): void {
        this.results = [];
        this.addSelectAllItem();
        this.isDropdownVisible = false;
        if (this.inputElement && this.inputElement.nativeElement)
            this.inputElement.nativeElement.value = '';
        this.clearSelected.emit();
    }
}
