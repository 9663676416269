import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpringTesterComponent } from './spring-tester.component';

const routes: Routes = [{ path: '', component: SpringTesterComponent, title: "Spring Tester" }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SpringTesterRoutingModule { }
