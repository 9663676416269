import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpringTesterRoutingModule } from './spring-tester-routing.module';
import { SpringTesterComponent } from './spring-tester.component';
import { RtcSignalrModule } from '@mt-ng2/rtc-module';
import { RtcRoomsResolverService } from '../admin-portal/common/Libraries/RtcRoomsResolver';
import { AdminPortalSharedModule } from '@common/shared.module';


@NgModule({
  declarations: [
    SpringTesterComponent
  ],
  imports: [
    CommonModule,
    SpringTesterRoutingModule,
    RtcSignalrModule,
    AdminPortalSharedModule
  ],
  providers: [RtcRoomsResolverService],
})
export class SpringTesterModule
{
    static forRoot(): any {
        return {
            ngModule: SpringTesterModule,
            providers: []
        };
    }
}
