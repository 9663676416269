import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ISpringTesterComparisonThreshold } from '@model/interfaces/spring-tester-comparison-threshold';
import { Observable } from 'rxjs';

export const emptySpringTesterComparisonThreshold: ISpringTesterComparisonThreshold = {
    Archived: null,
    ComparisonThresholdMax: null,
    ComparisonThresholdMin: null,
    DateCreated: null,
    DateModified: null,
    Id: 0,
    IsDefault: null,
};

@Injectable({
    providedIn: 'root',
})
export class SpringTesterComparisonThresholdService extends BaseService<ISpringTesterComparisonThreshold> {
    constructor(public http: HttpClient) {
        super('/spring-tester-comparison-threshold', http, null, {entityName: 'Spring Tester'});
    }

    formatTitleText(springTester: ISpringTesterComparisonThreshold): void {
        this.setTitle(`Spring Tester Comparison Threshold: ${new Date(springTester.DateModified).toLocaleString()}`);
    }

    getEmptySpringTesterComparisonThreshold(): ISpringTesterComparisonThreshold {
        return { ...emptySpringTesterComparisonThreshold };
    }

    getCurrentComparisonThreshold(): Observable<ISpringTesterComparisonThreshold> {
        return this.http.get<ISpringTesterComparisonThreshold>('/spring-tester-comparison-threshold/current');
    }

    getDefaultConfiguration(): Observable<ISpringTesterComparisonThreshold> {
        return this.http.get<ISpringTesterComparisonThreshold>('/spring-tester-comparison-threshold/default');
    }

    resetToDefault(): Observable<ISpringTesterComparisonThreshold> {
        return this.http.get<ISpringTesterComparisonThreshold>('/spring-tester-comparison-threshold/reset');
    }
}
