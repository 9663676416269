import { Component, OnInit } from '@angular/core';
import { RtcRoomsResolverService } from '@common/Libraries/RtcRoomsResolver';
import { ISpringTesterPayload } from 'model/Rtc/MessagePayloads/SpringTesterPayload';
import { forkJoin, Subscription } from 'rxjs';
import { SpringTesterService } from './spring-tester.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SpringTesterComparisonThresholdService } from '../admin-portal/spring-tester-configurations/services/spring-tester-comparison-threshold.service';
import { SpringTesterConfigurationService } from 'admin-portal/spring-tester-configurations/services/spring-tester-configuration.service';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/enums/claim-types';

@Component({
  selector: 'spring-tester',
  templateUrl: './spring-tester.component.html',
  styleUrls: ['./spring-tester.component.css']
})
export class SpringTesterComponent implements OnInit {

    minTestId = 25;
    maxTestId = 45;

    resultText = '';
    subscriptions = new Subscription();
    testerPayload = this.springTesterService.getEmptySpringTesterPayload();
    heightAt = "Height@Load";
    newTestId = 0;
    resultClass = '';
    adjustmentResultClass = '';
    testIdMessage = `Test ID must be a whole number between ${this.minTestId} and ${this.maxTestId}`;
    defaultAdjustmentValue = 0;
    decimalPlaces = 3;
    canGenerateRandomTests = false;

    constructor(
        private rtcRoomsResolverService: RtcRoomsResolverService,
        private springTesterService: SpringTesterService,
        private thresholdService: SpringTesterComparisonThresholdService,
        private configService: SpringTesterConfigurationService,
        private claimsService: ClaimsService,
        private notificationService: NotificationsService
    ) { }

    ngOnInit(): void {
        this.canGenerateRandomTests =
            this.claimsService.hasClaim(ClaimTypes.SpringTesterConfigurations as number, [ClaimValues.FullAccess, ClaimValues.ReadOnly]) &&
            this.claimsService.hasClaim(ClaimTypes.ForceTesterConfigurations as number, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.getActiveConfigurationAndThreshold();
        this.subscriptions.add(
            this.rtcRoomsResolverService.getSpringTesterRoom().subscribe((data: ISpringTesterPayload) => {
                this.notificationService.success('Test file generated');
                this.testerPayload = data;
                this.heightAt = `Height@${this.testerPayload.testId as number}`;
                this.newTestId = this.testerPayload.testId as number;
                this.resultText = this.testerPayload.isPassing ? 'Use It' : 'Scrap It';
                this.resultClass = this.testerPayload.isPassing ? ' background-success' : ' background-danger';
                this.adjustmentResultClass = this.testerPayload.isPassing ? ' border-success' : ' border-danger';
            }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getActiveConfigurationAndThreshold(): void {
        forkJoin([
            this.thresholdService.getCurrentComparisonThreshold(),
            this.configService.getCurrentConfiguration()
        ]).subscribe(([threshold, config]) => {
            this.testerPayload.clutchPlateGroveDistance = config.ClutchPlateGroveDistance;
            this.testerPayload.theoreticalPresetHeight = config.TheoreticalPresetHeight;
            this.testerPayload.currentSpringThickness = config.CurrentSpringThickness;
            this.testerPayload.comparisonThresholdMin = threshold.ComparisonThresholdMin;
            this.testerPayload.comparisonThresholdMax = threshold.ComparisonThresholdMax;
        });
    }

    generateRandomTestFile(): void {
        this.springTesterService.generateRandomTestFile().subscribe();
    }

    addTestId(): void {
        if (this.newTestId >= this.minTestId && this.newTestId <= this.maxTestId) {
            this.springTesterService.addTestId(this.newTestId).subscribe({
                next: x => {
                    if (x === true) {
                        this.testerPayload.runNo = '0';
                        this.notificationService.success('Test ID added');
                    }
                },
                error: () => {
                    this.notificationService.error(this.testIdMessage);
                }
            });
        }
        else{
            this.notificationService.error(this.testIdMessage);
            this.newTestId = this.testerPayload ? this.testerPayload.testId : 0;
        }
    }

    get adjustedValueString(): string {
        if (this.testerPayload !== null && this.testerPayload.adjustmentValue !== null ) {
            const value = this.testerPayload.adjustmentValue.toFixed(this.decimalPlaces) as string;
            return this.testerPayload.adjustmentValue > 0 ? `+${value}` : `${value}`;
        }
        return this.defaultAdjustmentValue.toFixed(this.decimalPlaces);
    }
}
