<div class="typeahead-container">
    <input #inputElement
        type="text"
        [placeholder]="placeholder"
        (focus)="isDropdownVisible = true"
        class="form-control"
        [value]="selectedValue"
        (keydown)="backspaceCheck($event)"
        (click)="isDropdownVisible = true">
    <div *ngIf="isDropdownVisible" class="dropdown">
        <ul>
            <li *ngFor="let result of results" (click)="onSelect(result)">{{ result.name }}</li>
        </ul>
    </div>
</div>
