import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISpringTesterMeasurement } from '@model/interfaces/spring-tester-measurement';
import { BaseService } from '@mt-ng2/base-service';
import { ISpringTesterPayload } from 'model/Rtc/MessagePayloads/SpringTesterPayload';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class SpringTesterService extends BaseService<ISpringTesterMeasurement> {
    constructor(public http: HttpClient) {
        super('/spring-tester', http);
    }

    getEmptySpringTesterPayload(): ISpringTesterPayload {
        return {
            adjustmentValue: 0,
            isPassing: false,
            testId: 0,
            runNo: '1',
            heightLoad: 0,
            theoreticalPresetHeight: 0,
            clutchPlateGroveDistance: 0,
            currentSpringThickness: 0,
            comparisonThresholdMin: 0,
            comparisonThresholdMax: 0,
        };
    }

    generateRandomTestFile(): Observable<boolean> {
        return this.http.post<boolean>('/api/v1/spring-tester/generate-test-file', null);
    }

    addTestId(newTestId: number): Observable<boolean> {
        return this.http.post<boolean>(`/api/v1/spring-tester/add-test-id/${newTestId}`, null);
    }
}
