export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    Customers_CanChangeStatus = 5,
    UserRoles = 4,
    Audit = 6,
    AccessRequests = 7,
    SpringTesterConfigurations = 8,
    ForceTesterConfigurations = 9,
    RunChart= 10
}
