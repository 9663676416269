import { NgModule } from '@angular/core';
import { MtSearchBarControlModule } from '@mt-ng2/searchbar-control';
import { EntityListModule } from '@mt-ng2/entity-list-module';
import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { DynamicSearchFiltersModule } from '@mt-ng2/dynamic-search-filters';
import { DebouncedTypeAheadComponent } from './debounced-type-ahead/debounced-type-ahead.component';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { FormsModule } from '@angular/forms';
import { DynamicFormModule } from '@mt-ng2/dynamic-form';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        MtSearchBarControlModule,
        TypeAheadModule,
        FormsModule,
        DynamicFormModule,
        EntityListModule,
        MtSearchFilterSelectModule,
        DynamicSearchFiltersModule,
        CommonModule
    ],
    declarations: [DebouncedTypeAheadComponent],
    exports: [DebouncedTypeAheadComponent],
})
export class CommonAcrossPortalsModule {}
