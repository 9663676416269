import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ISpringTesterConfiguration } from '@model/interfaces/spring-tester-configuration';
import { Observable } from 'rxjs';

export const emptySpringTesterConfiguration: ISpringTesterConfiguration = {
    Archived: null,
    ClutchPlateGroveDistance: null,
    CurrentSpringThickness: null,
    DateCreated: null,
    DateModified: null,
    Id: 0,
    IsDefault: null,
    TheoreticalPresetHeight: null,
};

@Injectable({
    providedIn: 'root',
})
export class SpringTesterConfigurationService extends BaseService<ISpringTesterConfiguration> {
    constructor(public http: HttpClient) {
        super('/spring-tester-configurations', http, null, {entityName: 'Spring Tester'});
    }

    formatTitleText(springTester: ISpringTesterConfiguration): void {
        this.setTitle(`Spring Tester Configuration: ${new Date(springTester.DateModified).toLocaleString()}`);
    }

    getEmptySpringTesterConfiguration(): ISpringTesterConfiguration {
        return { ...emptySpringTesterConfiguration };
    }

    getCurrentConfiguration(): Observable<ISpringTesterConfiguration> {
        return this.http.get<ISpringTesterConfiguration>('/spring-tester-configurations/current');
    }

    getDefaultConfiguration(): Observable<ISpringTesterConfiguration> {
        return this.http.get<ISpringTesterConfiguration>('/spring-tester-configurations/default');
    }

    resetToDefault(): Observable<ISpringTesterConfiguration> {
        return this.http.get<ISpringTesterConfiguration>('/spring-tester-configurations/reset');
    }
}
